export const validateItems = (items, newValidationErrors) => {
  let valid = true;
  const newItemsValidationErrors = items.map((item) => {
    const itemValidationErrors = {
      description: validateDescription(item.description),
      quantity: validateQuantity(item.quantity),
      amount: validateAmount(item.amount),
    };

    if (Object.values(itemValidationErrors).some((error) => error !== "")) {
      valid = false;
    }

    return itemValidationErrors;
  });

  return { valid, newItemsValidationErrors };
};

export const validateDescription = (description) => {
  if (!description) {
    return "Description is required";
  } else if (description.length > 15) {
    return "Max 15 characters allowed.";
  }
  return "";
};

export const validateQuantity = (quantity) => {
  if (!quantity) {
    return "Quantity is required";
  } else if (isNaN(quantity)) {
    return "Must be a valid number";
  } else if (Number(quantity) <= 0) {
    return "Must be a positive number";
  } else if (!/^\d+$/.test(quantity)) {
    return "Must be a whole number";
  }
  return "";
};

export const validateAmount = (amount) => {
  if (!amount) {
    return "Amount is required";
  } else if (isNaN(amount)) {
    return "Must be a valid number";
  } else if (Number(amount) <= 0) {
    return "Must be a positive number";
  } else if (!/^\d*\.?\d{0,2}$/.test(amount)) {
    return "Max 2 decimal places";
  }
  return "";
};
