import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif",
    fontSize: 13,
    h6: {
      fontSize: "15px",
      fontWeight: "bold",
    },
    body1: {
      fontSize: "13px",
    }
  },
  
});

export default theme;
