import React from 'react';
import { Button, Typography } from "@mui/material";
import { DateRangePicker } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';

function FilterComponent({
  orderCreatedDate,
  setOrderCreatedDate,
  orderExpiryDate,
  setOrderExpiryDate,
  orderStatus,
  setOrderStatus,
  searchKey,
  setSearchKey,
  handleDialogOpen,
  handleSendData,
  handleClearCustomSearch,
  handleKeyDown
}) {
  return (
    <form className='search-options'>
      <div className='container'>
        <button type="button" className='primary-btn' onClick={handleDialogOpen}>+ Payment Link Order</button>
      </div>
      <div className='date-time'>
        <div className='dateRangePicker'>
          <div className='dateLabal'>
            <small>Order Created Date</small>
            <DateRangePicker
              format="yyyy-MM-dd HH:mm"
              value={orderCreatedDate}
              style={{ width: 360 }}
              showMeridian
              onChange={(value) => setOrderCreatedDate(value)}
            />
          </div>
          <div className='dateLabal'>
            <small>Order Expiry Date</small>
            <DateRangePicker
              format="yyyy-MM-dd HH:mm"
              value={orderExpiryDate}
              style={{ width: 360 }}
              showMeridian
              onChange={(value) => setOrderExpiryDate(value)}
            />
          </div>
        </div>
      </div>
      <div className='options'>
        <div className='search-section'>
          <select
            name="status"
            value={orderStatus}
            onChange={(e) => setOrderStatus(e.target.value)}
          >
            <option>Select Order Status</option>
            <option value="ALL">All</option>
            <option value="CREATED">Created</option>
            <option value="VIEWED">Viewed</option>
            <option value="ATTEMPTED">Attempted</option>
            <option value="PAID">Paid</option>
            <option value="EXPIRING">Expiring</option>
            <option value="EXPIRED">Expired</option>
          </select>
          <input
            className='search-input'
            placeholder='Enter Order ID / Merchant Order No / Cust Ref No / Cust Email here'
            type="search"
            value={searchKey || ''}
            onChange={(e) => setSearchKey(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className='btn-grp'>
          <Button onClick={handleSendData} variant="outlined" style={{ backgroundColor: "#f8f8f8", borderColor: "#aeacac", color: "black", minWidth: "115px" }}>
            <Typography style={{ textTransform: 'none' }}>Search</Typography>
          </Button>
          <Button type='reset' onClick={handleClearCustomSearch} variant="outlined" style={{ backgroundColor: "#f8f8f8", borderColor: "#aeacac", color: "black", minWidth: "115px" }}>
            <Typography style={{ textTransform: 'none' }}>Clear All</Typography>
          </Button>
        </div>
      </div>
    </form>
  );
}

export default FilterComponent;
