import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  InputAdornment,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import Icon from "@mui/material/Icon";
import OrderItemsTable from "../components/OrderItemsTable";
import { saveOrder } from "../api/paymentOrderAPI";
import {
  validateForm,
  validateMerchantOrderNo,
  validateCustomerRefNo,
  validateCustomerPhone,
} from "../util/FormValidation";
import { validateItems } from "../util/ItemValidation";
import { updateTotalPaymentAmount } from "../util/PaymentCalculation";
import ConfirmDiscardDialog from "./ConfirmDiscardDialog";
import ConfirmSaveDialog from "./ConfirmSaveDialog";
import OrderLoading from "./OrderLoading";
import OrderError from "./OrderError";
import OrderSuccess from "./OrderSuccess";

const initialFormData = {
  merchantOrderNo: "",
  customerId: "",
  customerRefNo: "",
  customerFirstName: "",
  customerEmail: "",
  customerLastName: "",
  paymentDue: 30,
  customerPhone: "",
  totalPaymentAmount: "0.00",
  paymentLinkDelivery: "NOTREQ",
};
const initialValidationErrors = {
  merchantOrderNo: "",
  customerId: "",
  customerRefNo: "",
  customerFirstName: "",
  customerEmail: "",
  customerSurname: "",
  paymentDue: "",
  customerPhone: "",
  totalPaymentAmount: "",
  itemsError: false,
  items: [],
  paymentLinkDelivery: "",
};

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    height: "35px",
    borderRadius: "0",
    backgroundColor: "white",
    paddingTop: "0px",
    "&.Mui-focused fieldset": {
      borderColor: "#0B456E",
    },
    "& input": {
      padding: "7px 14px",
    },
  },
  "& .MuiFormHelperText-root": {
    position: "absolute",
    top: "32px",
    paddingLeft: "5px",
  },
});

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "0",
  },
  "&.MuiOutlinedInput-root": {
    height: "35px",
    backgroundColor: "white",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0B456E",
    },
  },
}));

const CreateNewOrderDialog = ({
  accessToken,
  open,
  handleClose,
  handleRefresh,
}) => {
  const initialItems = [{ description: "", quantity: 1, amount: "" }];

  const [formData, setFormData] = useState(initialFormData);
  const [items, setItems] = useState(initialItems);
  const [validationErrors, setValidationErrors] = useState(
    initialValidationErrors
  );
  const [confirmDiscardOpen, setConfirmDiscardOpen] = useState(false);
  const [confirmSaveOpen, setConfirmSaveOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddItem = () => {
    const { valid: itemValid, newItemsValidationErrors } = validateItems(items);
    updateItemValidationErrors(newItemsValidationErrors);
    if (itemValid) {
      setItems([...items, { description: "", quantity: 1, amount: "" }]);
    }
  };

  const handleRemoveItem = (index) => {
    const newItems = items.filter((item, i) => i !== index);
    setItems(newItems);
    updateTotalPaymentAmount(newItems);
    const updatedTotal = updateTotalPaymentAmount(newItems);
    setFormData({ ...formData, totalPaymentAmount: updatedTotal });
  };

  const handleItemChange = (index, event) => {
    const { name, value } = event.target;
    const newItems = [...items];
    newItems[index][name] = value;
    setItems(newItems);
    const updatedTotal = updateTotalPaymentAmount(newItems);
    setFormData({ ...formData, totalPaymentAmount: updatedTotal });
  };

  const updateItemValidationErrors = (newItemErrors) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      items: newItemErrors,
    }));
  };

  const handleDiscardClick = () => {
    setConfirmDiscardOpen(true);
  };

  const handleConfirmDiscard = () => {
    setConfirmDiscardOpen(false);
    handleDialogClose();
  };

  const handleDialogClose = () => {
    setFormData(initialFormData);
    setItems(initialItems);
    setValidationErrors(initialValidationErrors);
    setLoading(false);
    setError(false);
    setSuccess(false);
    handleClose();
  };

  const handleSuccessClose = () => {
    // handleRefresh(); // TODO: Decided later
    handleDialogClose();
  };

  const handleSaveClick = () => {
    const { valid, newValidationErrors } = validateForm(
      formData,
      items,
      initialValidationErrors
    );
    setValidationErrors(newValidationErrors);
    if (valid) {
      setConfirmSaveOpen(true);
    }
  };

  const handleConfirmSave = async () => {
    try {
      setLoading(true);

      console.log("Form Data:", formData);
      console.log("Items Data:", items);

      const responseData = await saveOrder(accessToken, {
        ...formData,
        items,
      });
      console.log("Order saved successfully:", responseData.data);
      setSuccessMessage(responseData.data);
      setSuccess(true);
    } catch (error) {
      console.error("Error saving order:", error);
      // Extract the response body from the error object
      let errorMessage = error.message;
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage =
          error.response.data.message || error.response.data.Message;
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = "Server is not responding. Please try again later.";
      } else {
        // Something happened in setting up the request that triggered an Error
        errorMessage = "Something went wrong";
      }
      setErrorMessage(errorMessage);
      setError(true);
    } finally {
      setLoading(false);
      setConfirmSaveOpen(false);
    }
  };

  if (loading) {
    return <OrderLoading handleDialogClose={handleDialogClose} open={open} />;
  }

  if (error) {
    return (
      <OrderError
        message={errorMessage}
        handleDialogClose={handleDialogClose}
        open={open}
      />
    );
  }

  if (success) {
    return (
      <OrderSuccess
        handleDialogClose={handleSuccessClose}
        message={successMessage}
        open={open}
      />
    );
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      fullWidth
      PaperProps={{
        style: {
          width: "800px",
          height: "900px",
          maxWidth: "none",
        },
      }}
    >
      <DialogTitle
        id="form-dialog-title"
        style={{
          backgroundColor: "#0B456E",
          height: "55px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div style={{ color: "white" }}>
            <Typography variant="h6" component="div" style={{ color: "white" }}>
              Create Payment Link Order
            </Typography>
          </div>
          <div>
            <IconButton
              onClick={handleDiscardClick}
              aria-label="close"
              style={{ padding: "0" }}
            >
              <Icon
                className="fa-sharp fa-solid fa-xmark"
                style={{ color: "white" }}
              />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        style={{
          height: "37vh",
          backgroundColor: "#F9F9F9",
          paddingTop: "20px",
          paddingBottom: 0,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography>
              Merchant Order No.<span style={{ color: "#FD6D6D" }}>*</span>
            </Typography>
            <StyledTextField
              name="merchantOrderNo"
              variant="outlined"
              placeholder="Maximum of 64 characters"
              fullWidth
              value={formData.merchantOrderNo}
              onChange={(e) => {
                handleInputChange(e);
                const errorMessage = validateMerchantOrderNo(e.target.value);
                const newValidationErrors = {
                  ...validationErrors,
                  merchantOrderNo: errorMessage,
                };
                setValidationErrors(newValidationErrors);
              }}
              error={!!validationErrors.merchantOrderNo}
              helperText={validationErrors.merchantOrderNo}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              Customer Ref No.<span style={{ color: "#FD6D6D" }}>*</span>
            </Typography>
            <StyledTextField
              name="customerRefNo"
              variant="outlined"
              placeholder="Maximum of 64 characters"
              fullWidth
              value={formData.customerRefNo}
              onChange={(e) => {
                handleInputChange(e);
                const errorMessage = validateCustomerRefNo(e.target.value);
                const newValidationErrors = {
                  ...validationErrors,
                  customerRefNo: errorMessage,
                };
                setValidationErrors(newValidationErrors);
              }}
              error={!!validationErrors.customerRefNo}
              helperText={validationErrors.customerRefNo}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>Customer First Name</Typography>
            <StyledTextField
              name="customerFirstName"
              variant="outlined"
              fullWidth
              value={formData.customerFirstName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>Customer Last Name</Typography>
            <StyledTextField
              name="customerLastName"
              variant="outlined"
              fullWidth
              value={formData.customerLastName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>Customer ID</Typography>
            <StyledTextField
              name="customerId"
              variant="outlined"
              fullWidth
              value={formData.customerId}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>Customer Phone No.</Typography>
            <StyledTextField
              name="customerPhone"
              variant="outlined"
              placeholder="e.g. +61xxxxxxxxx or 61xxxxxxxxx"
              fullWidth
              onChange={(e) => {
                handleInputChange(e);
                const errorMessage = validateCustomerPhone(e.target.value);
                const newValidationErrors = {
                  ...validationErrors,
                  customerPhone: errorMessage,
                };
                setValidationErrors(newValidationErrors);
              }}
              value={formData.customerPhone}
              error={!!validationErrors.customerPhone}
              helperText={validationErrors.customerPhone}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              Customer Email
              {formData.paymentLinkDelivery === "EMAIL" ? (
                <span style={{ color: "#FD6D6D" }}> *</span>
              ) : (
                ""
              )}
            </Typography>
            <StyledTextField
              name="customerEmail"
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              value={formData.customerEmail}
              error={!!validationErrors.customerEmail}
              helperText={validationErrors.customerEmail}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography>Payment Link Delivery</Typography>
            <StyledSelect
              name="paymentLinkDelivery"
              value={formData.paymentLinkDelivery}
              onChange={handleInputChange}
              fullWidth
            >
              <MenuItem value={"NOTREQ"}>Not Required</MenuItem>
              <MenuItem value={"EMAIL"}>Email</MenuItem>
            </StyledSelect>
          </Grid>
          <Grid item xs={3}>
            <Typography>Payment Due In (Days)</Typography>
            <Autocomplete
              name="paymentDue"
              options={Array.from({ length: 60 }, (_, i) => i + 1)}
              getOptionLabel={(option) => option.toString()}
              value={formData.paymentDue}
              autoHighlight={true}
              autoSelect={true}
              onChange={(event, newValue) => {
                handleInputChange({
                  target: { name: "paymentDue", value: newValue },
                });
              }}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  name="paymentDue"
                  variant="outlined"
                  error={!!validationErrors.paymentDue}
                  helperText={validationErrors.paymentDue}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogContent
        style={{
          height: "28vh",
          backgroundColor: "#F9F9F9",
          paddingTop: 0,
          paddingBottom: 0,
          overflowY: "scroll",
        }}
      >
        <Typography color={validationErrors.itemsError ? "error" : undefined}>
          At least one line item is required
          <span style={{ color: "#FD6D6D" }}> *</span>
        </Typography>
        <Divider style={{ marginBottom: "10px" }} />
        <OrderItemsTable
          items={items}
          validationErrors={validationErrors.items}
          handleItemChange={handleItemChange}
          handleAddItem={handleAddItem}
          handleRemoveItem={handleRemoveItem}
          updateItemValidationErrors={updateItemValidationErrors}
        />
      </DialogContent>
      <Divider />
      <DialogContent
        style={{
          backgroundColor: "#F9F9F9",
          height: "7vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8} style={{ paddingRight: "20px" }}>
            <Typography align="right">Total Payment Amount (AUD)</Typography>
          </Grid>
          <Grid item xs={4} style={{ marginLeft: "-15px" }}>
            <StyledTextField
              name="totalPaymentAmount"
              variant="outlined"
              value={formData.totalPaymentAmount}
              disabled
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        style={{ backgroundColor: "#E9E9E9", paddingRight: "40px" }}
      >
        <Button
          label="Save"
          variant="contained"
          onClick={handleSaveClick}
          style={{
            backgroundColor: "#0B456E",
            color: "white",
            minWidth: "100px",
          }}
        >
          Save
        </Button>
        <Button
          label="Discard"
          onClick={handleDiscardClick}
          variant="outlined"
          sx={{
            color: "#707777",
            borderColor: "#707777",
            "&:hover": {
              borderColor: "#707777",
            },
            minWidth: "100px",
          }}
        >
          Discard
        </Button>
      </DialogActions>
      <ConfirmDiscardDialog
        open={confirmDiscardOpen}
        handleClose={() => setConfirmDiscardOpen(false)}
        handleConfirm={handleConfirmDiscard}
      />
      <ConfirmSaveDialog
        open={confirmSaveOpen}
        handleClose={() => setConfirmSaveOpen(false)}
        handleConfirm={handleConfirmSave}
      />
    </Dialog>
  );
};

export default CreateNewOrderDialog;
