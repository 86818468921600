import React from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";
import Loader from "./common/Loader";

export default function OrderLoading({ handleDialogClose, open }) {
  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          width: "400px",
          height: "200px",
          maxWidth: "none",
        },
      }}
    >
      <DialogTitle
        id="id"
        style={{
          backgroundColor: "#0B456E",
          height: "55px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div style={{ color: "white" }}>
            <Typography variant="h6" component="div" style={{ color: "white" }}>
              Create Payment Link Order
            </Typography>
          </div>
        </div>
      </DialogTitle>
      <DialogContent style={{ backgroundColor: "#F9F9F9", paddingTop: "35px" }}>
        <Box textAlign="center" style={{ paddingTop: "5px" }}>
          <Loader />
          <Typography variant="h6" align="center" style={{ paddingTop: "5px" }}>
            Loading
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
