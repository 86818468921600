export default function createData(orderId, orderCreatedDate, status, txnId, total, merchantOrderNo, customerRefNo, customerEmail, expiryDate, paymentLink, actionIcons) {
    return {
      orderId,
      orderCreatedDate,
      status,
      txnId,
      total,
      merchantOrderNo,
      customerRefNo,
      customerEmail,
      expiryDate,
      paymentLink,
      actionIcons
    };
  }