import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Typography,
  Box,
  InputAdornment,
  Link,
  Tooltip,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Icon from "@mui/material/Icon";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    height: "35px",
    borderRadius: "0",
    backgroundColor: "#E9E9E9",
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "black",
    color: "black",
  },
});

export default function OrderSuccess({ message, handleDialogClose, open }) {
  return (
    <Dialog
      open={open}
      aria-labelledby="order-success-title"
      PaperProps={{
        style: {
          width: "450px",
          height: "420px",
          maxWidth: "none",
        },
      }}
    >
      <DialogTitle
        id="order-success-title"
        style={{
          backgroundColor: "#0B456E",
          height: "55px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div style={{ color: "white" }}>
            <Typography variant="h6" component="div" style={{ color: "white" }}>
              Order Success
            </Typography>
          </div>
          <div>
            <IconButton
              onClick={handleDialogClose}
              aria-label="close"
              style={{ padding: "0" }}
            >
              <Icon
                className="fa-sharp fa-solid fa-xmark"
                style={{ color: "white" }}
              />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: "#F9F9F9",
          paddingTop: 0,
          paddingLeft: "40px",
          paddingRight: "40px",
        }}
      >
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={3}>
            <Box>
              <img
                src="/success.svg"
                alt="Success"
                style={{
                  width: "75px",
                  margin: "20px auto",
                  display: "block",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Typography style={{ color: "#0B456E" }} variant="h6">
              Pay-by-link URL has been created successfully
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={3} style={{ paddingTop: "20px" }}>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ color: "#0B456E" }}>
              Order ID
            </Typography>
            <StyledTextField
              name="orderId"
              variant="outlined"
              fullWidth
              value={message.Result.OrderNo}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ paddingTop: "20px" }}>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ color: "#0B456E" }}>
              Pay-by-link-URL
            </Typography>
            <StyledTextField
              name="payByLinkUrl"
              variant="outlined"
              fullWidth
              value=""
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                        color: "#0000EE",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "block",
                        maxWidth: "300px",
                      }}
                    >
                      {message.Result.PaymentLink}
                    </Link>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <CopyToClipboard text={message.Result.PaymentLink}>
                      <Tooltip title="Copy link">
                        <IconButton>
                          <ContentCopyIcon
                            fontSize="small"
                            sx={{ color: "#0B456E" }}
                          />{" "}
                        </IconButton>
                      </Tooltip>
                    </CopyToClipboard>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#E9E9E9",
        }}
      >
        <Button
          label="OK"
          variant="contained"
          onClick={handleDialogClose}
          style={{
            backgroundColor: "#0B456E",
            color: "white",
            minWidth: "100px",
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
