import './App.css';
import EnhancedTable from './views/table/EnhancedTable'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import store from "./redux/store";
import {Provider} from "react-redux";
import UnAuthorized from './views/common/UnAuthorized';

function App() {
  return (
      <div className='App'>
        <Provider store={store}>
        <BrowserRouter>
              <Routes>
                <Route path='/' element={<EnhancedTable />} />
                <Route path='/unauthorized' element={<UnAuthorized />} />
              </Routes>
          </BrowserRouter>
        </Provider>
      </div>
  );
}

export default App;
