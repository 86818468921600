const headCells = [
    {
      id: 'orderId',
      numeric: false,
      disablePadding: true,
      label: 'Order ID',
    },
    {
      id: 'orderCreatedDate',
      numeric: false,
      disablePadding: false,
      label: 'Order Created Date',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
    {
      id: 'txnId',
      numeric: false,
      disablePadding: false,
      label: 'Transaction ID',
    },
    {
      id: 'total',
      numeric: true,
      disablePadding: true,
      label: 'Total Amount',
    },
    {
      id: 'merchantOrderNo',
      numeric: false,
      disablePadding: false,
      label: 'Merch. Order No',
    },
    {
      id: 'customerRefNo',
      numeric: false,
      disablePadding: false,
      label: 'Cust. Ref No',
    },
    {
      id: 'expiryDate',
      numeric: false,
      disablePadding: false,
      label: 'Expiry Date',
    },
    {
      id: "customerEmailAddress",
      numeric: false,
      disablePadding: false,
      label: "Cust. Email Address",
    },
    {
      id: 'paymentLink',
      numeric: false,
      disablePadding: false,
      label: 'Payment Link',
    },
    {
        id: 'actionIcons',
        numeric: false,
        disablePadding: false,
        label: '',
    }
  ];

    export default headCells;