import { CircularProgress, Grid } from "@mui/material";
import React from "react";

export default function Loader() {
  return (
    <div className="mt-5">
      <Grid container>
        <Grid item sm={12} align={"center"}>
          <CircularProgress color="inherit" />
        </Grid>
      </Grid>
    </div>
  );
}
