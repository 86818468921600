import axios from 'axios'


export const GetCustomOrder = (token, orderCreatedDate, orderExpiryDate, orderStatus, searchKey, pageNumber, pageSize) => {

  const payload = {
    "pageNo": pageNumber + 1,
    "pageSize": pageSize,
    "filter": {
      "searchValue": searchKey,
      "orderStatus": getOrderStatus(orderStatus),
      "orderCreatedDate": {
        "from": getOrderCreatedDateFrom(orderCreatedDate),
        "to": getOrderCreatedDateTo(orderCreatedDate)
      },
      "orderExpiryDate": {
        "from": getOrderExpiryDateFrom(orderExpiryDate),
        "to": getOrderExpiryDateTo(orderExpiryDate)
      }
    },
    "sort": {
      "fieldName": "orderCreatedDate",
      "direction": "desc"
    }
  };


  try {
    const response = axios.post(process.env.REACT_APP_ORDER_QUERY_URL + "/v1/query", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }); 
    return response;
  } catch (error) {
    throw error;
  }
}

function getOrderStatus(orderStatus) {
  if ("Select Order Status" === orderStatus || "ALL" === orderStatus) {
      return null;
  }
  return orderStatus;
}

function getOrderCreatedDateFrom(orderCreatedDate) {
  if (orderCreatedDate != null) {
      return orderCreatedDate[0].getTime();
  }
  return null;
}

function getOrderCreatedDateTo(orderCreatedDate) {
  if (orderCreatedDate != null) {
      return orderCreatedDate[1].getTime();
  }
  return null;
}


function getOrderExpiryDateFrom(orderExpiryDate) {
  if (orderExpiryDate != null) {
      return orderExpiryDate[0].getTime();
  }
  return null;
}

function getOrderExpiryDateTo(orderExpiryDate) {
  if (orderExpiryDate != null) {
      return orderExpiryDate[1].getTime();
  }
  return null;
}


