import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import headCells from './headCells';


export default function EnhancedTableHead() {

  return (
    <TableHead>
      <TableRow
        sx={{ backgroundColor: '#0A456D' }}
      >
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={{ color: 'white', fontSize: 'small', textAlign: 'center', whiteSpace: 'nowrap', fontWeight :'bold' }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}