import {SET_SNACKBAR} from "../actions/actionTypes"
import initialState from "./initialState";

export default function snackbarReducer(state = initialState.snackbar, action){
    switch (action.type) {
        case SET_SNACKBAR:
            const { snackbarOpen, snackbarMessage, snackbarType } = action;
            return {
                ...state,
                snackbarOpen,
                snackbarType,
                snackbarMessage
            };
        default:
            return state;
    }

}