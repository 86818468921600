import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Button,
  Grid,
  Typography,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  validateDescription,
  validateQuantity,
  validateAmount,
} from "../util/ItemValidation";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-head": {
    backgroundColor: "#0B456E",
    color: "white",
    fontWeight: "bold",
    border: "1px solid rgba(255,255,255, 1)",
  },
  "&.MuiTableCell-body": {
    border: "0px",
    padding: "0px",
    height: "55px",
    paddingTop: "5px",
  },
  "&.MuiTableCell-sizeSmall:last-child": {
    paddingRight: 0,
  },
}));

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    height: "35px",
    borderRadius: "0",
    backgroundColor: "white",
    "&.Mui-focused fieldset": {
      borderColor: "#0B456E",
    },
    "& input": {
      padding: "7px 14px",
    },
  },
  "& .MuiFormHelperText-root": {
    position: "absolute",
    top: "32px",
    paddingLeft: "5px",
  },
});

const OrderItemsTable = ({
  items,
  validationErrors,
  handleItemChange,
  handleAddItem,
  handleRemoveItem,
  updateItemValidationErrors,
}) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: "45%" }}>
              Item Description
            </StyledTableCell>
            <StyledTableCell style={{ width: "25%" }}>Quantity</StyledTableCell>
            <StyledTableCell style={{ width: "25%" }}>
              <Grid container spacing={0} alignItems="center">
                <Grid item>
                  <Typography variant="body2">
                    <b>Item Amount</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip
                    title={
                      <Typography variant="body2" sx={{ fontSize: "14px" }}>
                        Maximum 2 Decimal Places (x.xx)
                      </Typography>
                    }
                  >
                    <IconButton size="small">
                      <HelpOutlineIcon
                        fontSize="small"
                        sx={{ color: "white" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </StyledTableCell>
            <StyledTableCell style={{ width: "5%" }}></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow key={index}>
              <StyledTableCell>
                <StyledTextField
                  name="description"
                  aria-label="description"
                  variant="outlined"
                  error={
                    validationErrors.length > index
                      ? !!validationErrors[index]?.description
                      : undefined
                  }
                  helperText={
                    validationErrors.length > index
                      ? validationErrors[index]?.description
                      : ""
                  }
                  value={item.description}
                  onChange={(e) => handleItemChange(index, e)}
                  onBlur={() => {
                    const errorMessage = validateDescription(item.description);
                    const newValidationErrors = [...validationErrors];
                    newValidationErrors[index] = {
                      ...newValidationErrors[index],
                      description: errorMessage,
                    };
                    updateItemValidationErrors(newValidationErrors);
                  }}
                  fullWidth
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextField
                  name="quantity"
                  aria-label="quantity"
                  variant="outlined"
                  error={
                    validationErrors.length > index
                      ? !!validationErrors[index]?.quantity
                      : undefined
                  }
                  helperText={
                    validationErrors.length > index
                      ? validationErrors[index]?.quantity
                      : ""
                  }
                  value={item.quantity}
                  onChange={(e) => {
                    handleItemChange(index, e);
                    const errorMessage = validateQuantity(e.target.value);
                    const newValidationErrors = [...validationErrors];
                    newValidationErrors[index] = {
                      ...newValidationErrors[index],
                      quantity: errorMessage,
                    };
                    updateItemValidationErrors(newValidationErrors);
                  }}
                  fullWidth
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledTextField
                  name="amount"
                  aria-label="amount"
                  variant="outlined"
                  placeholder="e.g. 10.99"
                  fullWidth
                  error={
                    validationErrors.length > index
                      ? !!validationErrors[index]?.amount
                      : undefined
                  }
                  helperText={
                    validationErrors.length > index
                      ? validationErrors[index]?.amount
                      : ""
                  }
                  value={item.amount}
                  onChange={(e) => {
                    handleItemChange(index, e);
                    const amount = e.target.value;
                    const errorMessage = validateAmount(amount);
                    const newValidationErrors = [...validationErrors];
                    newValidationErrors[index] = {
                      ...newValidationErrors[index],
                      amount: errorMessage,
                    };
                    updateItemValidationErrors(newValidationErrors);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    onClick={() => handleRemoveItem(index)}
                    aria-label="remove-item"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              </StyledTableCell>
            </TableRow>
          ))}
          <TableRow>
            <StyledTableCell colSpan={4} align="right">
              <Button
                onClick={handleAddItem}
                style={{ color: "#0B456E", textTransform: "none" }}
              >
                <b>
                  <u>+ Add More</u>
                </b>
              </Button>
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderItemsTable;
