import React from 'react';

function LinkShortener({ link }) {
  if (link != null && link.length > 46) {
    return (
      <div className='link-view'>
        <span>{link}</span>
      </div>
    );
  }
  return <span>{link}</span>;
}

export default LinkShortener;
