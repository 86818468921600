import React, { useEffect, useState } from 'react';
import { Box, Grid } from "@mui/material";
import CreateNewOrderDialog from "../CreateNewOrderDialog";
import { Chip } from '../../style';
import { connect } from "react-redux";
import moment from 'moment';
import { GetCustomOrder } from '../../api/OrderQueryApi';
import formatDateToYYYYMMDDHHmm from '../../util/DateTimeUtils';
import createData from '../../util/DataUtils';
import FilterComponent from '../../components/FilterComponent';
import ResultTableComponent from '../../components/ResultTableComponent';
import { authenticate } from "../../redux/actions/authActions";
import 'rsuite/DateRangePicker/styles/index.css';
import ActionIcons from '../../components/common/ActionIcons';
import LinkShortener from '../../components/common/LinkShortener';
import Loader from '../../views/common/Loader';


function EnhancedTable({ authState, authenticate }) {
  const currentTime = moment();
  const sixMonthsAgo = moment().subtract(6, 'months');
  const oneMonthAgo = moment().subtract(1, 'months');
  const sixMonthsFuture = moment().add(6, 'months');
  const [orderCreatedDate, setOrderCreatedDate] = useState([sixMonthsAgo.startOf('day').toDate(), currentTime.endOf('day').toDate()]);
  const [orderExpiryDate, setOrderExpiryDate] = useState([oneMonthAgo.startOf('day').toDate(), sixMonthsFuture.endOf('day').toDate()]);
  const [orderStatus, setOrderStatus] = useState('');
  const [searchKey, setSearchKey] = useState(null);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);


  const rows = data.map((item) => {
    return createData(
      item.id,
      formatDateToYYYYMMDDHHmm(item.createdTime),
      <Chip label={item.status} />,
      item.paymentTransactionId,
      item.orderAmount,
      item.merchantExternalReference,
      item.customerReference,
      item.customer.email,
      new Date(item.expiry).toISOString().slice(0, 10),
      <LinkShortener link={item.paymentLinkUrl} />,
      <ActionIcons item={item} accessToken={authState.accessToken} />
    );
  });

 

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('s');
    setMainLoading(true);
    authenticate(sessionId);
  }, [authenticate]);

  useEffect(() => {
    if (authState.isAuthorized && authState.payByLinkEnabled) {
      setLoading(true);
      const fetchData = async () => {
        try {
          const response = await GetCustomOrder(authState.accessToken, orderCreatedDate, orderExpiryDate, orderStatus, searchKey, page, rowsPerPage);
          setData(response.data.data);
          setTotalRecords(response.data.totalRecords);
        } catch (error) {
          console.error(error);
        } finally {
          setMainLoading(false);
          setLoading(false);
        }
      };
      fetchData();
    } else {
      setMainLoading(false);
      setLoading(false);
    }
  }, [authState, orderCreatedDate, orderExpiryDate, orderStatus, searchKey, page, rowsPerPage]);

  if (mainLoading) {
    return <Loader></Loader>;
  }

  const handleChangePage = async (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
    const response = await GetCustomOrder(authState.accessToken, orderCreatedDate, orderExpiryDate, orderStatus, searchKey, newPage, rowsPerPage);
    setData(response.data.data);
    setTotalRecords(response.data.totalRecords);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    const response = await GetCustomOrder(authState.accessToken, orderCreatedDate, orderExpiryDate, orderStatus, searchKey, page, parseInt(event.target.value, 10));
    setData(response.data.data);
    setTotalRecords(response.data.totalRecords);
  };

  const handleDialogOpen = () => setIsDialogOpen(true);
  const handleDialogClose = () => setIsDialogOpen(false);
  const handleSendData = async (e) => {
    e.preventDefault();
    const response = await GetCustomOrder(authState.accessToken, orderCreatedDate, orderExpiryDate, orderStatus, searchKey, 0, rowsPerPage);
    setData(response.data.data);
    setTotalRecords(response.data.totalRecords);
  };

  const handleClearCustomSearch = async () => {
    setOrderStatus("");
    setSearchKey(null);
    setOrderExpiryDate(null);
    setOrderCreatedDate(null);
    const response = await GetCustomOrder(authState.accessToken, null, null, null, null, 0, rowsPerPage);
    setData(response.data.data);
    setTotalRecords(response.data.totalRecords);
  };

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const response = await GetCustomOrder(authState.accessToken, orderCreatedDate, orderExpiryDate, orderStatus, searchKey, 0, rowsPerPage);
      setData(response.data.data);
      setTotalRecords(response.data.totalRecords);
    }
  }

  if (authState.isAuthorized && authState.payByLinkEnabled) {
    return (
      <Box sx={{ width: '100%', padding: '2rem' }}>
        <div className='container'>
          <CreateNewOrderDialog
            accessToken={authState.accessToken}
            open={isDialogOpen}
            handleClose={handleDialogClose}
            handleRefresh={handleClearCustomSearch}
          />
        </div>
        <FilterComponent
          orderCreatedDate={orderCreatedDate}
          setOrderCreatedDate={setOrderCreatedDate}
          orderExpiryDate={orderExpiryDate}
          setOrderExpiryDate={setOrderExpiryDate}
          orderStatus={orderStatus}
          setOrderStatus={setOrderStatus}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          handleDialogOpen={handleDialogOpen}
          handleSendData={handleSendData}
          handleClearCustomSearch={handleClearCustomSearch}
          handleKeyDown={handleKeyDown}
        />
        <ResultTableComponent
          data={data}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecords={totalRecords}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          visibleRows={rows.slice(0, rowsPerPage)}
          loading={loading}
        />
      </Box>
    );
  } else if (!authState.isAuthorizing && !authState.payByLinkEnabled) {
    return (
      <div className="mt-3">
        <Grid container >
          <Grid item sm={12} align={"center"}>
            <h2 align={"center"} style={{ color: '#0A456D' }}>Merchant is not enabled for Pay-By-Link. Please contact support if you require this feature.</h2>
          </Grid>
        </Grid>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  authState: state.auth
});

const mapDispatchToProps = {
  authenticate
};

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedTable);
