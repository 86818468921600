import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import Icon from "@mui/material/Icon";

const ConfirmSaveDialog = ({ open, handleClose, handleConfirm }) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="confirm-dialog-title"
      PaperProps={{
        style: {
          width: "400px",
          height: "200px",
          maxWidth: "none",
        },
      }}
    >
      <DialogTitle
        id="confirm-dialog-title"
        style={{
          backgroundColor: "#0B456E",
          height: "55px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div style={{ color: "white" }}>
            <Typography variant="h6" component="div" style={{ color: "white" }}>
              Save Order
            </Typography>
          </div>
          <div>
            <IconButton
              onClick={handleClose}
              aria-label="close"
              style={{ padding: "0" }}
            >
              <Icon
                className="fa-sharp fa-solid fa-xmark"
                style={{ color: "white" }}
              />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent style={{ backgroundColor: "#F9F9F9", paddingTop: "20px" }}>
        <Typography fontSize={14} fontWeight={500} style={{ color: "#0B456E" }}>
          Do you wish to save this order?
        </Typography>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#E9E9E9",
        }}
      >
        <Button
          onClick={handleConfirm}
          style={{
            backgroundColor: "#0B456E",
            color: "white",
            minWidth: "100px",
          }}
        >
          Yes
        </Button>
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{
            color: "#707777",
            borderColor: "#707777",
            "&:hover": {
              borderColor: "#707777",
            },
            minWidth: "100px",
          }}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmSaveDialog;
