import React from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  DialogActions,
  Button,
  Grid,
  Divider,
} from "@mui/material";
import Icon from "@mui/material/Icon";

export default function OrderError({ message, handleDialogClose, open }) {
  return (
    <Dialog
      open={open}
      aria-labelledby="order-failed-title"
      PaperProps={{
        style: {
          width: "450px",
          height: "300px",
          maxWidth: "none",
        },
      }}
    >
      <DialogTitle
        id="order-failed-title"
        style={{
          backgroundColor: "#D46063",
          height: "55px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div style={{ color: "white" }}>
            <Typography variant="h6" component="div" style={{ color: "white" }}>
              Order Failed
            </Typography>
          </div>
          <div>
            <IconButton
              onClick={handleDialogClose}
              aria-label="close"
              style={{ padding: "0" }}
            >
              <Icon
                className="fa-sharp fa-solid fa-xmark"
                style={{ color: "white" }}
              />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: "#F9F9F9",
          paddingTop: 0,
          paddingLeft: "40px",
          paddingRight: "40px",
        }}
      >
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={3}>
            <Box>
              <img
                src="/cancel.svg"
                alt="Success"
                style={{
                  width: "60px",
                  margin: "20px auto",
                  display: "block",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Typography style={{ color: "#0B456E" }} variant="h6">
              Pay-by-link URL order could not be created at this time
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Box textAlign="center" style={{ paddingTop: "20px" }}>
          <Typography fontSize={14} fontWeight={500} variant="body1">
            {message}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#E9E9E9",
        }}
      >
        <Button
          label="OK"
          variant="contained"
          onClick={handleDialogClose}
          style={{
            backgroundColor: "#D46063",
            color: "white",
            minWidth: "100px",
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
