export const updateTotalPaymentAmount = (items) => {
  const total = parseFloat(
    items.reduce((sum, item) => {
      const amount = parseFloat(item.amount);
      const quantity = parseFloat(item.quantity);
      
      if (!isNaN(amount) && !isNaN(quantity) && amount >= 0 && quantity >= 0) {
        return sum + amount * quantity;
      }
      return sum;
    }, 0)
  ).toFixed(2);
  
  return total;
};