import { Chip as MuiChip} from '@mui/material';
import styled from '@emotion/styled';

export const Chip = styled(MuiChip)`
    width: 6rem;
    background-color: ${props => 
        props.label === 'CREATED' ? '#E9C28B' :
        props.label === 'VIEWED' ? '#9EF6FF' :
        props.label === 'PAID' ? '#8EFFAA' :
        props.label === 'EXPIRED' ? '#FF6D6D' :
        props.label === 'EXPIRING' ? '#FFA555' :
        props.label === 'ATTEMPTED' ? '#A004FC' :

        'transparent'
    };
    color: ${props => 
        props.label === 'ATTEMPTED' ? 'white' :

        '#000000DE'
    };
`