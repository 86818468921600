import axios from "axios";

export const saveOrder = async (token, orderData) => {
  const paymentLinkDelivery =
    orderData.paymentLinkDelivery === "NOTREQ"
      ? ""
      : orderData.paymentLinkDelivery;
  const payload = {
    Type: "PaymentLink",
    MerchantOrderNo: orderData.merchantOrderNo,
    Amount: parseInt(orderData.totalPaymentAmount * 1000),
    Currency: "AUD",
    CustomerReference: orderData.customerRefNo,
    Customer: {
      CustomerID: orderData.customerId,
      EmailAddress: orderData.customerEmail,
      MobileNo: orderData.customerPhone,
      FirstName: orderData.customerFirstName,
      LastName: orderData.customerLastName,
    },
    LineItems: orderData.items.map((item) => ({
      Description: item.description,
      Amount: parseInt(item.amount * 1000),
      Quantity: item.quantity,
    })),
    PaymentLink: {
      ExpiryDays: orderData.paymentDue,
      PaymentLinkDelivery: paymentLinkDelivery,
    },
  };

  try {
    const response = await axios.post(
      process.env.REACT_APP_ORDER_QUERY_URL + "/v1/create",
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};


export const resendPaymentLink = async (token, orderId) => {

  const payload = {
    orderId: orderId
  };

  try {
    const response = await axios.post(
        process.env.REACT_APP_ORDER_QUERY_URL + "/v1/resend-payment-link",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
    );
    return response;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};

