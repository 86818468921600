import {AUTH_FAILED, AUTH_SUCCESS} from "../actions/actionTypes"
import initialState from "./initialState";


export default function authReducer(state = initialState.auth, action){
    switch (action.type) {
        case AUTH_SUCCESS:
            return {
                ...state,
                accessToken: action.payload.accessToken,
                merchantId: action.payload.merchantId,
                isServerError: false,
                isAuthorized: true,
                isAuthorizing: false,
                payByLinkEnabled: action.payload.scopes != null
            };
        case AUTH_FAILED:
            return {
                isAuthorizing: false,
                isAuthorized: false,
                isServerError: action.reason === 'SERVER_ERROR',
                payByLinkEnabled:false
            }
        default:
            return state;
    }

}