import React from 'react';
import { Table, TableBody, TableContainer, TablePagination, Paper, TableCell, TableRow } from "@mui/material";
import EnhancedTableHead from '../views/table/EnhancedTableHead';
import Loader from '../views/common/Loader';

function ResultTableComponent({
  data,
  rowsPerPage,
  page,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  visibleRows,
  loading
}) {
  if (loading) {
    return <Loader></Loader>;
  }

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <EnhancedTableHead />
          <TableBody>
            {visibleRows.length > 0 ? (
              visibleRows.map((row, index) => (
                <TableRow tabIndex={-1} key={index}>
                  <TableCell className="wrap-text-cell" align="center">{row.orderId}</TableCell>
                  <TableCell className="wrap-text-cell" align="center">{row.orderCreatedDate}</TableCell>
                  <TableCell className="wrap-text-cell" align="center">{row.status}</TableCell>
                  <TableCell className="wrap-text-cell" align="center">{row.txnId}</TableCell>
                  <TableCell className="wrap-text-cell" align="right">${row.total.toFixed(2)}</TableCell>
                  <TableCell className="wrap-text-cell" align="center">{row.merchantOrderNo}</TableCell>
                  <TableCell className="wrap-text-cell" align="center">{row.customerRefNo}</TableCell>
                  <TableCell className="wrap-text-cell" align="center">{row.expiryDate}</TableCell>
                  <TableCell className="wrap-text-cell" align="center">{row.customerEmail}</TableCell>
                  <TableCell className="wrap-text-cell2" align="center">{row.paymentLink}</TableCell>
                  <TableCell className="wrap-text-cell" align="center">{row.actionIcons}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center" style={{ color: '#0A456D', fontWeight: 'bold', fontSize: '14px' }}>
                  No result found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton
        showLastButton
      />
    </Paper>
  );
}

export default ResultTableComponent;
