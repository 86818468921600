import { validateItems } from "./ItemValidation";

export const validateMerchantOrderNo = (merchantOrderNo) => {
  if (!merchantOrderNo) {
    return "Merchant Order No. is required";
  } else if (merchantOrderNo.length > 64) {
    return "Merchant Order No. must be less than 65 characters";
  }
  return "";
};

export const validateCustomerRefNo = (customerRefNo) => {
  if (!customerRefNo) {
    return "Customer Ref No. is required";
  } else if (customerRefNo.length > 64) {
    return "Customer Ref No. must be less than 65 characters";
  }
  return "";
};

const validatePaymentDue = (paymentDue) => {
  if (!paymentDue || isNaN(paymentDue)) {
    return "Payment Due Days must be a valid number";
  } else if (paymentDue < 1 || paymentDue > 60) {
    return "Payment Due Days must be between 1 and 60";
  }
  return "";
};

export const validateCustomerPhone = (customerPhone) => {
  if (customerPhone === "") return "";
  if (!/^[+\d]/.test(customerPhone)) {
    return "Phone number must start with a number or '+'";
  } else if (!/^\+?\d+$/.test(customerPhone)) {
    return "Phone number must contain only numbers after the first character";
  }
  return "";
};

export const validateCustomerEmail = (customerEmail, paymentLinkDelivery) => {
  if (paymentLinkDelivery !== "EMAIL" && !customerEmail) return "";
  if (paymentLinkDelivery === "EMAIL" && !customerEmail) {
    return "Customer Email is required";
  }
  if (
    customerEmail &&
    !/^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/.test(
      customerEmail
    )
  ) {
    return "Must be a valid email";
  }
  return "";
};

export const validateForm = (formData, items, initialValidationErrors) => {
  let valid = true;
  let newValidationErrors = { ...initialValidationErrors };

  // Validate form fields
  const merchantOrderNoError = validateMerchantOrderNo(
    formData.merchantOrderNo
  );
  if (merchantOrderNoError) {
    newValidationErrors.merchantOrderNo = merchantOrderNoError;
    valid = false;
  }

  const customerRefNoError = validateCustomerRefNo(formData.customerRefNo);
  if (customerRefNoError) {
    newValidationErrors.customerRefNo = customerRefNoError;
    valid = false;
  }

  const paymentDueError = validatePaymentDue(formData.paymentDue);
  if (paymentDueError) {
    newValidationErrors.paymentDue = paymentDueError;
    valid = false;
  }

  const customerPhoneError = validateCustomerPhone(formData.customerPhone);
  if (customerPhoneError) {
    newValidationErrors.customerPhone = customerPhoneError;
    valid = false;
  }

  const customerEmailError = validateCustomerEmail(
    formData.customerEmail,
    formData.paymentLinkDelivery
  );
  if (customerEmailError) {
    newValidationErrors.customerEmail = customerEmailError;
    valid = false;
  }

  if (items.length === 0) {
    newValidationErrors.itemsError = true;
    valid = false;
  }

  // Validate updated items
  const { valid: itemsValid, newItemsValidationErrors } = validateItems(items);
  newValidationErrors.items = newItemsValidationErrors;
  if (!itemsValid) {
    valid = false;
  }

  console.log("Validation Errors: ", newValidationErrors);
  return { valid, newValidationErrors };
};
