export default {
    auth: {
        accessToken: "",
        merchantId: "",
        isAuthorizing: true,
        isAuthorized: false,
        isServerError: false,
        payByLinkEnabled: false
    },
    snackbar: {
        snackbarOpen: false,
        snackbarType: "success",
        snackbarMessage: ""
    }
}