import React from 'react';
import { IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import EmailButton from "../../views/EmailButton";

function ActionIcons({ item, accessToken }) {
  if (item.paymentLinkUrl != null && item.paymentLinkUrl.length > 46) {
    return (
      <div className='link-view'>
        <CopyToClipboard text={item.paymentLinkUrl}>
          <Tooltip title="Copy link">
            <IconButton>
              <ContentCopyIcon
                  fontSize="small"
                  sx={{ color: "#0B456E" }}
              />
            </IconButton>
          </Tooltip>
        </CopyToClipboard>
        <EmailButton
            accessToken={accessToken}
            order={item}
        />
      </div>
    );
  }
  return null;
}

export default ActionIcons;
